export default {
  _id(state) {
    return state._id;
  },
  name(state) {
    return state.name;
  },
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    return !!state.token;
  },
  user(state) {
    return state.user;
  },
  authHeader(state) {
    return {
      Authorization: 'Bearer ' + state.token,
    };
  },
};
