import { createImageToken } from '@/utils/utils';
import Dexie from 'dexie';
import noImage from '@/assets/noImage.jpg';
import urls from '@/utils/urls';

const db = new Dexie('Images');
db.version(1).stores({
  relative: '_id,image,imageToken',
  teacher: '_id,image,imageToken',
  student: '_id,image,imageToken',
});
db.open();

const getTarget = (targetName) => {
  if (targetName === 'relative') {
    return db.relative;
  } else if (targetName === 'student') {
    return db.student;
  } else if (targetName === 'teacher') {
    return db.teacher;
  } else {
    return null;
  }
};

const addImage = async (id, image, targetName) => {
  const imageToken = createImageToken(image);
  const target = getTarget(targetName);
  if (!target) return;

  try {
    await target.add({
      _id: id,
      image,
      imageToken,
    });
  } catch (err) {
    if (err.message.includes('Key already exists'))
      await target.update(id, {
        image,
        imageToken,
      });
    else {
      console.error(err);
    }
  }
};

const updateImage = async (id, image, targetName) => {
  const imageToken = createImageToken(image);
  const target = getTarget(targetName);
  if (!target) return;

  await target.update(id, {
    image,
    imageToken,
  });
};

const fetchImage = async (id, targetName, tokenHeader) => {
  console.log('tokenHeader1', tokenHeader, targetName);
  try {
    let url;
    if (targetName === 'relative') {
      url = urls.relativeImage;
    } else if (targetName === 'student') {
      url = urls.studentImage;
    } else if (targetName === 'teacher') {
      url = urls.teacherImage;
    } else {
      throw new Error('No url');
    }

    const headers = {
      ...tokenHeader,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const response = await fetch(url + id, {
      headers,
    });
    const responseData = await response.json();
    if (response.status == 200) {
      return responseData.image;
    } else {
      throw new Error(responseData.message);
    }
  } catch (error) {
    console.error(error);
    return '';
  }
};

const getImage = async (item, targetName, tokenHeader) => {
  if (
    !item._id ||
    !item.imageToken ||
    item.imageToken === createImageToken('')
  ) {
    return noImage;
  }
  const imageObject = await getImageObject(item, targetName);
  if (!imageObject) {
    const newImage = await fetchImage(item._id, targetName, tokenHeader);
    if (newImage) {
      await addImage(item._id, newImage, targetName);
      return newImage;
    } else {
      return noImage;
    }
  } else if (imageObject.image) {
    if (imageObject.imageToken === item.imageToken) {
      return imageObject.image;
    } else {
      const newImage = await fetchImage(item._id, targetName, tokenHeader);
      if (newImage) {
        await updateImage(item._id, newImage, targetName);
        return newImage;
      } else {
        return noImage;
      }
    }
  }
  return noImage;
};

const getImageObject = async (item, targetName) => {
  const target = getTarget(targetName);
  if (!target) return;
  try {
    return await target.get(item._id);
  } catch (err) {
    console.error('err', err);
    return null;
  }
};

export default {
  addRelativeImage(id, image) {
    addImage(id, image, 'relative');
  },
  addStudentImage(id, image) {
    addImage(id, image, 'student');
  },
  addTeacherImage(id, image) {
    addImage(id, image, 'teacher');
  },
  async getRelativeImage(item, tokenHeader) {
    return await getImage(item, 'relative', tokenHeader);
  },
  async getStudentImage(item, tokenHeader) {
    return await getImage(item, 'student', tokenHeader);
  },
  async getTeacherImage(item, tokenHeader) {
    return await getImage(item, 'teacher', tokenHeader);
  },
};
