<template>
  <footer>
    <nav>
      <ul>
        <router-link to="/"> Home </router-link>
      </ul>
      <ul>
        <li>
          <img :src="logoImage" />
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
import logoImage from '@/assets/logo.png';
export default {
  props: {
    links: {},
  },
  async created() {
    const _image = await this.getUserImage();
    this.userImage = _image;
  },
  data() {
    return {
      logoImage: logoImage,
    };
  },
};
</script>

<style scoped>
footer {
  width: 100%;
  height: 6rem;
  background-color: var(--color3);
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  margin-top: 1rem;
}

footer a {
  text-decoration: none;
  color: var(--color2);
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid var(--color2);
}

h1 {
  margin: 0;
  color: var(--color2);
}

h1 a {
  color: var(--color1);
  margin: 0;
}
h4 {
  color: var(--color2);
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

footer nav {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}

li img {
  height: 4rem;
}
</style>
