const authRoot = '/auth';
const schoolRoot = '/school';
const teacherRoot = '/teacher';
const relativeRoot = '/relative';

const auth = {
  root: authRoot,
  logInSchool: authRoot + '/log-in-school',
  signUpSchool: authRoot + '/sign-up-school',
  logInTeacher: authRoot + '/log-in-teacher',
  signUpTeacher: authRoot + '/sign-up-teacher',
  logInRelative: authRoot + '/log-in-relative',
  signUpRelative: authRoot + '/sign-up-relative',
};
const school = {
  root: schoolRoot,
  listClassrooms: schoolRoot + '/listClassrooms',
  getClassroom: schoolRoot + '/getClassroom/:classroomId',
  listStudents: schoolRoot + '/listStudents',
  getStudent: schoolRoot + '/getStudent/:studentId',
  getLatestPickUps: schoolRoot + '/latest-pick-ups',
};
const teacher = {
  root: teacherRoot,
  getClassroom: teacherRoot + '/getClassroom/:classroomId',
  getStudent: teacherRoot + '/getStudent/:studentId',
  //listStudents: teacherRoot + '/listStudents',
  getLatestPickUps: teacherRoot + '/latest-pick-ups',
};
const relative = {
  root: relativeRoot,
  getLatestPickUps: relativeRoot + '/latest-pick-ups',
  pickUp: relativeRoot + '/pick-up',
};

export default {
  auth: auth,
  school: school,
  teacher: teacher,
  relative: relative,
};
