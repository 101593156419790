const RelativeView = () => import('../views/RelativeView.vue');
import store from '@/store/vuex/index.js';

const GetLatestPickUps = () =>
  import('@/components/pickUp/GetLatestPickUps.vue');
const PickUp = () => import('@/components/pickUp/PickUp.vue');

import routes from '@/utils/routes';

export default [
  {
    path: routes.relative.root,
    //name: 'relative',
    component: RelativeView,
    redirect: routes.relative.pickUp,
    children: [
      {
        path: routes.relative.getLatestPickUps,
        component: GetLatestPickUps,
      },
      {
        path: routes.relative.pickUp,
        component: PickUp,
      },
    ],

    beforeEnter: (_to, _from, next) => {
      if (!store.getters['authRelative/isAuthenticated']) {
        next('/auth');
      } else {
        next();
      }
    },
  },
];
