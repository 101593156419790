import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuexStore from './store/vuex';

import tokenMixin from '@/mixins/token';
import dialogMixin from '@/mixins/dialog';

import VueAwesomePaginate from 'vue-awesome-paginate';

import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseBackdrop from './components/ui/BaseBackdrop.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import BaseWrapper from './components/ui/BaseWrapper.vue';
import BaseInput from './components/ui/BaseInput.vue';
import BaseFormInput from './components/ui/form/BaseFormInput.vue';
import BaseForm from './components/ui/form/BaseForm.vue';
import BaseDropdown from './components/ui/form/BaseDropdown.vue';
import BaseAvatarCropper from './components/ui/BaseAvatarCropper.vue';
import BaseCheckBox from './components/ui/BaseCheckBox.vue';

import routes from './utils/routes';
import urls from './utils/urls';
import { images } from '@/store/dexie';

const app = createApp(App);

app.config.globalProperties.$routes = routes;
app.config.globalProperties.$urls = urls;
app.config.globalProperties.$images = images;

app
  .mixin(tokenMixin)
  .mixin(dialogMixin)
  .use(vuexStore)
  .use(router)
  .use(VueAwesomePaginate)
  .component('BaseCard', BaseCard)
  .component('BaseButton', BaseButton)
  .component('BaseSpinner', BaseSpinner)
  .component('BaseBackdrop', BaseBackdrop)
  .component('BaseDialog', BaseDialog)
  .component('BaseWrapper', BaseWrapper)
  .component('BaseInput', BaseInput)
  .component('BaseFormInput', BaseFormInput)
  .component('BaseForm', BaseForm)
  .component('BaseDropdown', BaseDropdown)
  .component('BaseAvatarCropper', BaseAvatarCropper)
  .component('BaseCheckBox', BaseCheckBox)
  .mount('#app');
