<template>
  <BaseFormItem :label="label" :vuelidateVar="vuelidateVar" :error="checkError">
    <BaseInput
      :type="type"
      v-model="modelValue"
      @input="inputEvent"
      :class="setErrorClass"
      @blur="onBlur"
      :autocomplete="!autoComplete ? 'on' : 'off'"
      :inside-form-item="true"
    />
  </BaseFormItem>
</template>

<script>
import BaseFormItem from './BaseFormItem.vue';
import inputValidationMixin from '@/mixins/inputValidation';

export default {
  mixins: [inputValidationMixin],
  props: {
    label: null,
    type: {
      default: 'text',
    },
    autoComplete: {
      default: true,
    },
  },
  methods: {
    inputEvent(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    onBlur() {
      if (this.vuelidateVar) {
        this.vuelidateVar.$touch();
      }
    },
  },
  components: { BaseFormItem },
};
</script>

<style scoped></style>
@/mixins/inputValidation
