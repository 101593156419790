<!--- Copied from 'https://github.com/romainsimon/vue-simple-search-dropdown/blob/master/src/Dropdown.vue' and edited --->

<template>
  <BaseFormItem
    :label="label"
    :vuelidateVar="vuelidateVar"
    :error="checkError"
    :image="image"
  >
    <div class="dropdown" v-if="filteredOptions">
      <!-- Dropdown Input -->
      <input
        :class="setErrorClass"
        :name="name"
        @focus="showOptions()"
        @blur="exit()"
        @keyup="keyMonitor"
        v-model="searchFilter"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
      />

      <!-- Dropdown Menu -->
      <div class="dropdown-content" v-show="optionsShown">
        <div
          class="dropdown-item"
          @mousedown="selectOption(option)"
          v-for="(option, index) in filteredOptions"
          :key="index"
        >
          {{ option[this.primaryKey] || option._id || '-' }}
        </div>
      </div>
    </div></BaseFormItem
  >
</template>

<script>
import BaseFormItem from './BaseFormItem.vue';
import inputValidationMixin from '@/mixins/inputValidation';

export default {
  mixins: [inputValidationMixin],
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown',
    },
    query: {
      type: String,
      required: false,
      note: 'Query to fetch data',
    },
    sendToken: {
      type: Boolean,
      default: false,
    },
    emptyOption: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    initalSelected: {
      type: Object,
      default: null,
    },
    primaryKey: {
      type: String,
      default: 'name',
    },
    responseKey: {
      type: String,
      default: 'names',
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: '',
      filteredOptions: [],
    };
  },
  async created() {
    await this.fetchOptions();
    this.$emit('selected', this.selected);
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected[this.primaryKey];
      this.$emit('selected', this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        //  this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      if (this.vuelidateVar) {
        this.vuelidateVar.$touch();
      }
      if (!this.selected._id) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected[this.primaryKey];
      }
      this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === 'Enter' && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    },
    async fetchOptions() {
      try {
        const response = await fetch(
          this.query + this.searchFilter,
          this.sendToken
            ? {
                headers: this.getTokenHeader,
              }
            : {}
        );
        const fetchedData = await response.json();
        this.filteredOptions = fetchedData.names;
        if (this.emptyOption) {
          const emptyObject = {};
          emptyObject[this.primaryKey] = '';
          emptyObject[this._id] = '';
          this.filteredOptions.push(emptyObject);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    async searchFilter() {
      await this.fetchOptions();

      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit('filter', this.searchFilter);
      this.$emit('update:modelValue', this.searchFilter && this.selected._id);
    },
    initalSelected(newValue) {
      if (newValue) {
        this.selectOption(newValue);
      }
    },
  },
  components: { BaseFormItem },
};
</script>

<style scoped>
input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid var(--color3);
  background-color: var(--color3_1);
  padding: 0.3rem;
}

input:focus,
textarea:focus {
  border-color: var(--color2);
  background-color: var(--color1);
  outline: none;
}
.input-error,
.input-error:focus {
  border: 1px dashed var(--color5);
}

.dropdown {
  position: relative;
  display: block;
  margin: auto;
}

.dropdown-content {
  position: absolute;
  background-color: var(--color3);
  min-width: 248px;
  max-width: 248px;
  max-height: 248px;
  border: 1px solid var(--color1);
  box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
  overflow: auto;
  z-index: 1;
}

.dropdown-item {
  color: var(--color2);
  font: inherit;
  font-size: 0.9rem;
  line-height: 1em;
  padding: 8px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.dropdown:hover .dropdowncontent {
  display: block;
}
.dropdown-item:hover {
  background-color: var(--color1);
}
</style>
@/mixins/validation/inputValidation @/mixins/inputValidation
