import { createRouter, createWebHistory } from 'vue-router';

import homeRoutes from '@/router/home';
import schoolRoutes from '@/router/school';
import teacherRoutes from '@/router/teacher';
import relativeRoutes from '@/router/relative';
import errorRoutes from '@/router/error';

const routes = [
  ...homeRoutes,
  ...schoolRoutes,
  ...teacherRoutes,
  ...relativeRoutes,
  ...errorRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
