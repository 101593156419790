export default {
  emits: ['update:modelValue'],
  props: {
    vuelidateVar: null,
    modelValue: null,
  },
  computed: {
    setErrorClass() {
      return this.checkError ? 'input-error' : '';
    },
    checkError() {
      return (
        this.vuelidateVar &&
        this.vuelidateVar.$dirty &&
        this.vuelidateVar.$silentErrors[0] &&
        this.vuelidateVar.$silentErrors[0].$message
      );
    },
  },
};
