const HomeView = () => import('../views/HomeView.vue');
import authRoutes from '@/router/auth';

export default [
  {
    path: '/',
    redirect: '/auth', //temporary
    name: 'home',
    component: HomeView,
    children: [...authRoutes],
  },
];
