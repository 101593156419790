<template>
  <router-view />
  <TheFooter v-show="!isLoading" /><BaseDialog v-model="error">
    <h4>{{ error }}</h4>
    <ul>
      <li v-for="err in errors" :key="err">{{ err.msg }}</li>
    </ul>
  </BaseDialog>
  <BaseDialog v-model="isLoading" title="Loading..." fixed>
    <BaseSpinner></BaseSpinner>
  </BaseDialog>
</template>

<script>
import TheFooter from '@/components/layout/TheFooter.vue';
export default {
  components: {
    TheFooter,
  },
  data() {
    return { isLoading: false, error: null, errors: [] };
  },
  methods: {
    setLoading(value) {
      this.isLoading = value;
    },
    setError(value) {
      this.error = value;
    },
    _setErrors(value) {
      this.errors = value;
    },
  },
  provide() {
    return {
      _setLoading: this.setLoading,
      _setError: this.setError,
      _setErrors: this._setErrors,
    };
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap');
:root {
  --font-family: 'Oswald', sans-serif;
  --color1: #fbf8f8;
  --color2: #0b3404;
  --color2_1: #0b3404ef;
  --color3: #dadb9e;
  --color3_1: #dadb9e0e;
  --color3_2: rgb(247, 247, 239);
  --color4: #faf6ff;
  --color5: red;
}
* {
  box-sizing: border-box;
}
html,
body {
  font-family: var(--font-family);
  background: var(--color3_1);
  color: var(--color2);
  margin: 0;
  padding: 0;
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
h1,
h2,
h3,
h4,
p {
  margin: 0.5rem 0;
  padding: 0;
}
</style>
