export default {
  setUser(state, payload) {
    state.name = payload.name;
    state._id = payload._id;
    state.token = payload.token;
    state.user = payload;
  },
  clearUser(state) {
    state.name = null;
    state._id = null;
    state.token = null;
    state.user = {};
  },
  setToken(state, payload) {
    state.token = payload;
  },
  clearToken(state) {
    state.token = null;
  },
};
