//const root = 'http://localhost:8080';
//To check on mobile
//const root = 'http://192.168.1.188:8080';
//
const root = 'https://server.bokul.com.tr';

const authRoot = root + '/auth';
const classroomRoot = root + '/classroom';
const studentRoot = root + '/student';
const teacherRoot = root + '/teacher';
const schoolRoot = root + '/school';
const imageRoot = root + '/image';
const relativeRoot = root + '/relative';
const assignmentRoot = root + '/assignment';
const pickUpRoot = root + '/pick-up';

export default {
  root: root,
  //AUTH URLS
  authRoot: authRoot,
  logInSchool: authRoot + '/school/login',
  signUpSchool: authRoot + '/school/signup',
  logInTeacher: authRoot + '/teacher/login',
  signUpTeacher: authRoot + '/teacher/signup',
  logInRelative: authRoot + '/relative/login',
  signUpRelative: authRoot + '/relative/signup',

  //TEACHER URLS
  teacherRoot: teacherRoot,
  teacherNames: teacherRoot + '/names?value=',

  //SCHOOL URLS
  schoolRoot: schoolRoot,
  schoolNames: schoolRoot + '/names?value=',

  //RELATIVE URLS
  relativeRoot: relativeRoot,
  relativeNames: relativeRoot + '/names?value=',

  //CLASSROOM URLS
  listClassrooms: classroomRoot + '/list',
  getClassroom: classroomRoot + '/',
  postClassroom: classroomRoot + '/',
  updateClassroom: classroomRoot + '/',
  deleteClassroom: classroomRoot + '/',
  classroomNames: classroomRoot + '/names?value=',

  //STUDENT URLS
  listStudents: studentRoot + '/list',
  getStudent: studentRoot + '/',
  postStudent: studentRoot + '/',
  updateStudent: studentRoot + '/',
  deleteStudent: studentRoot + '/',
  studentNames: studentRoot + '/names?value=',

  //IMAGE URLS
  relativeImage: imageRoot + '/relative/',
  studentImage: imageRoot + '/student/',
  teacherImage: imageRoot + '/teacher/',

  //ASSIGNMENT URLS
  assignRelative: assignmentRoot + '/assign-relative',
  dismissRelative: assignmentRoot + '/dismiss-relative',
  assignStudent: assignmentRoot + '/assign-student',
  dismissStudent: assignmentRoot + '/dismiss-student',

  //PICK UP URLS
  latestPickUps: pickUpRoot + '/latest',
  pickUpStudent: pickUpRoot + '/student',
};
