const TeacherView = () => import('../views/TeacherView.vue');
import store from '@/store/vuex/index.js';

//const ListStudents = () => import('@/components/student/ListStudents.vue');
const GetClassroom = () => import('@/components/classroom/GetClassroom.vue');
const GetStudent = () => import('@/components/student/GetStudent.vue');
const GetLatestPickUps = () =>
  import('@/components/pickUp/GetLatestPickUps.vue');

import routes from '@/utils/routes';

export default [
  {
    path: routes.teacher.root,
    name: 'teacher',
    component: TeacherView,
    redirect: routes.teacher.getLatestPickUps,
    children: [
      /*  {
        path: routes.teacher.listStudents,
        //name: 'listStudents',
        component: ListStudents,
      },*/
      {
        path: routes.teacher.getClassroom,
        //name: 'getClassroom',
        component: GetClassroom,
        meta: {
          isUpdate: true,
        },
      },
      {
        path: routes.teacher.getStudent,
        // name: 'getStudent',
        component: GetStudent,
        meta: {
          isUpdate: true,
        },
      },
      {
        path: routes.teacher.getLatestPickUps,
        // name: 'getLatestPickUps',
        component: GetLatestPickUps,
      },
    ],

    beforeEnter: (_to, _from, next) => {
      if (!store.getters['authTeacher/isAuthenticated']) {
        next('/auth');
      } else {
        next();
      }
    },
  },
];
