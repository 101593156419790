export default {
  methods: {
    setLoading() {
      this._setLoading(true);
    },
    clearLoading() {
      this._setLoading(false);
    },
    setError(error) {
      this._setError(error);
    },
    setErrors(errors) {
      this._setErrors(errors);
    },
    clearError() {
      this._setError(null);
    },
    clearErrors() {
      this._setErrors([]);
    },
  },
  inject: ['_setLoading', '_setError', '_setErrors'],
};
