const SchoolView = () => import('../views/SchoolView.vue');
import store from '@/store/vuex/index.js';

const ListClassrooms = () =>
  import('@/components/classroom/ListClassrooms.vue');
const GetClassroom = () => import('@/components/classroom/GetClassroom.vue');
const ListStudents = () => import('@/components/student/ListStudents.vue');
const GetStudent = () => import('@/components/student/GetStudent.vue');
const GetLatestPickUps = () =>
  import('@/components/pickUp/GetLatestPickUps.vue');

import routes from '@/utils/routes';

export default [
  {
    path: routes.school.root,
    //name: 'school',
    component: SchoolView,
    redirect: routes.school.listClassrooms,
    children: [
      {
        path: routes.school.listClassrooms,
        //name: 'listClassrooms',
        component: ListClassrooms,
      },
      {
        path: routes.school.getClassroom,
        //name: 'getClassroom',
        component: GetClassroom,
        meta: {
          isUpdate: true,
        },
      },
      {
        path: routes.school.listStudents,
        //name: 'listStudents',
        component: ListStudents,
      },
      {
        path: routes.school.getStudent,
        //name: 'getStudent',
        component: GetStudent,
        meta: {
          isUpdate: true,
        },
      },
      {
        path: routes.school.getLatestPickUps,
        //name: 'getLatestPickUps',
        component: GetLatestPickUps,
      },
    ],

    beforeEnter: (_to, _from, next) => {
      if (!store.getters['authSchool/isAuthenticated']) {
        next('/auth');
      } else {
        next();
      }
    },
  },
];
