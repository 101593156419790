export default {
  data() {
    return {};
  },
  methods: {
    setSchool(user) {
      this.$store.dispatch('authSchool/setUser', user);
    },
    setTeacher(user) {
      this.$store.dispatch('authTeacher/setUser', user);
    },
    setRelative(user) {
      this.$store.dispatch('authRelative/setUser', user);
    },
    clearSchool() {
      this.$store.dispatch('authSchool/clearUser');
    },
    clearTeacher() {
      this.$store.dispatch('authTeacher/clearUser');
    },
    clearRelative() {
      this.$store.dispatch('authRelative/clearUser');
    },
    logOutSchool() {
      this.clearSchool();
      this.$router.replace(this.$routes.auth.root);
    },
    logOutTeacher() {
      this.clearTeacher();
      this.$router.replace(this.$routes.auth.root);
    },
    logOutRelative() {
      this.clearRelative();
      this.$router.replace(this.$routes.auth.root);
    },
    logOut() {
      this.$store.dispatch(this.getAuthStorePath() + '/clearUser');
      this.$router.replace(this.$routes.auth.root);
    },
    getAuthStorePath() {
      if (this.userType === 'school') {
        return 'authSchool';
      } else if (this.userType === 'teacher') {
        return 'authTeacher';
      } else if (this.userType === 'relative') {
        return 'authRelative';
      } else {
        return '';
      }
    },
    async getUserImage() {
      let _image = '';
      if (this.userType === 'teacher') {
        _image = await this.$images.getTeacherImage(
          this.getUser,
          this.getTokenHeader
        );
      } else if (this.userType === 'relative') {
        _image = await this.$images.getRelativeImage(
          this.getUser,
          this.getTokenHeader
        );
      }
      return _image;
    },
    async getRelativeImage(item) {
      return await this.$images.getRelativeImage(item, this.getTokenHeader);
    },
    async getStudentImage(item) {
      return await this.$images.getStudentImage(item, this.getTokenHeader);
    },
    async getTeacherImage(item) {
      return await this.$images.getTeacherImage(item, this.getTokenHeader);
    },
  },
  computed: {
    userType() {
      if (
        this.$router.currentRoute._value.matched[0] &&
        this.$router.currentRoute._value.matched[0].path ===
          this.$routes.school.root
      ) {
        return 'school';
      } else if (
        this.$router.currentRoute._value.matched[0] &&
        this.$router.currentRoute._value.matched[0].path ===
          this.$routes.teacher.root
      ) {
        return 'teacher';
      } else if (
        this.$router.currentRoute._value.matched[0] &&
        this.$router.currentRoute._value.matched[0].path ===
          this.$routes.relative.root
      ) {
        return 'relative';
      }
      return '';
    },
    teachersClassroom() {
      return this.$store.getters['authTeacher/user'].classroom;
    },
    teachersClassromLink() {
      return this.$routes.teacher.getClassroom.replace(
        ':classroomId',
        this.teachersClassroom
      );
    },
    getUser() {
      return this.$store.getters[this.getAuthStorePath() + '/user'];
    },
    getUserName() {
      return this.$store.getters[this.getAuthStorePath() + '/name'];
    },
    getTokenHeader() {
      return this.$store.getters[this.getAuthStorePath() + '/authHeader'];
    },
    getToken() {
      return this.$store.getters[this.getAuthStorePath() + '/token'];
    },
    getUserId() {
      return this.$store.getters[this.getAuthStorePath() + '/_id'];
    },
    isSchoolAuthenticated() {
      return this.$store.getters['authSchool/isAuthenticated'];
    },
    isTeacherAuthenticated() {
      return this.$store.getters['authTeacher/isAuthenticated'];
    },
    isRelativeAuthenticated() {
      return this.$store.getters['authRelative/isAuthenticated'];
    },
  },
};
