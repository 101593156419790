import { createStore } from 'vuex';
//Stores in local store
import createPersistedState from 'vuex-persistedstate';

import authModule from './auth';

export default createStore({
  plugins: [createPersistedState()],
  modules: {
    //AUTH
    authSchool: authModule,
    authTeacher: authModule,
    authRelative: authModule,
  },
});
