<template>
  <teleport to="body">
    <div v-if="!!modelValue" @click="tryClose" class="backdrop"></div>

    <transition name="dialog" :style="dialogStyle">
      <dialog open v-if="!!modelValue">
        <slot></slot>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialogStyleTop: 10,
    };
  },
  emits: ['update:modelValue', 'close'],
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit('update:modelValue', null);
      this.$emit('close');
    },
  },
  watch: {
    modelValue(newVal) {
      if (newVal) {
        this.dialogStyleTop = window.top.scrollY + 20;
      }
    },
  },
  computed: {
    dialogStyle() {
      return 'top: ' + this.dialogStyleTop + 'px;';
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: absolute;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin: auto;
  background-color: var(--color1);
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>
