<template>
  <div>
    <avatar-cropper
      v-model="showCropper"
      :upload-handler="uploadFile"
      :cropper-options="{
        viewMode: 3,
        aspectRatio: 1,
        zoomable: false,
        minContainerWidth: length,
        minContainerWidth: length,
        minCropBoxWidth: length,
        maxCropBoxWidth: length,
        scalable: scalable,
      }"
      :aspectRatio="2"
    />
    <div class="wrapper">
      <img :src="modelValue" />
      <BaseButton
        type="button"
        mode="text"
        @click="clicked"
        :style="checkError ? 'color:var(--color5);' : ''"
        >Select an image</BaseButton
      >
    </div>
  </div>
</template>

<script>
import AvatarCropper from 'vue-avatar-cropper';
import inputValidationMixin from '@/mixins/inputValidation';

export default {
  mixins: [inputValidationMixin],
  props: {
    length: {
      default: 200,
    },
  },
  components: {
    AvatarCropper,
  },
  data() {
    return {
      showCropper: false,
    };
  },
  methods: {
    uploadFile(cropperInstance) {
      const naturalWidth = cropperInstance.imageData.naturalWidth;

      console.log('naturalWidth', naturalWidth);

      console.log(cropperInstance);

      if (
        cropperInstance.cropBoxData.width < this.length ||
        cropperInstance.cropBoxData.height < this.length
      ) {
        return this.setError(
          `Cropped image should be at least ${this.length}x${this.length}`
        );
      }
      this.$emit(
        'update:modelValue',
        cropperInstance
          .getCroppedCanvas({
            width: this.length,
            height: this.length,
          })
          .toDataURL('image/jpeg')
      );
    },
    clicked() {
      this.showCropper = true;
      if (this.vuelidateVar) {
        this.vuelidateVar.$touch();
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
}
img {
  position: relative;
  margin: 2px auto;
}
</style>
@/mixins/inputValidation
