export default {
  setUser(context, payload) {
    context.commit('setUser', payload);
  },
  clearUser(context) {
    context.commit('clearUser');
  },
  setToken(context, payload) {
    context.commit('setToken', payload);
  },
  clearToken(context) {
    context.commit('clearToken');
  },
};
