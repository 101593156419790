const AuthView = () => import('../views/AuthView.vue');

const LogInSchool = () => import('@/components/auth/LogInSchool.vue');
const SignUpSchool = () => import('@/components/auth/SignUpSchool.vue');
const LogInTeacher = () => import('@/components/auth/LogInTeacher.vue');
const SignUpTeacher = () => import('@/components/auth/SignUpTeacher.vue');
const LogInRelative = () => import('@/components/auth/LogInRelative.vue');
const SignUpRelative = () => import('@/components/auth/SignUpRelative.vue');

import routes from '@/utils/routes';

export default [
  {
    path: routes.auth.root,
    name: 'auth',
    component: AuthView,
    children: [
      {
        path: routes.auth.logInSchool,
        name: 'logInSchool',
        component: LogInSchool,
      },
      {
        path: routes.auth.signUpSchool,
        name: 'signUpSchool',
        component: SignUpSchool,
      },
      {
        path: routes.auth.logInTeacher,
        name: 'logInTeacher',
        component: LogInTeacher,
      },
      {
        path: routes.auth.signUpTeacher,
        name: 'signUpTeacher',
        component: SignUpTeacher,
      },
      {
        path: routes.auth.logInRelative,
        name: 'kogInRelative',
        component: LogInRelative,
        props: true,
      },
      {
        path: routes.auth.signUpRelative,
        name: 'signUpRelative',
        component: SignUpRelative,
      },
    ],
  },
];
