<template>
  <div class="form-control">
    <label for="id">{{ labelText }}</label>
    <img :src="image" v-if="image" />

    <slot />
    <label v-if="error" class="label-error">{{ error }}</label>
  </div>
</template>

<script>
export default {
  props: {
    label: null,
    vuelidateVar: null,    error: null,
    image: {
      default: '',
    },
  },
  computed: {
    labelText() {
      return this.label;
    },
  },
};
</script>

<style scoped>
div.form-control {
  margin: 0.5rem 0;
}
label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}
.label-error {
  color: var(--color5);
  font-weight: lighter;
}
img {
  display: block;
  margin: 0.5rem auto;
  width: 100%;
}
</style>
