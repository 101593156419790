export const createImageToken = (image) => {
  let token = '';
  for (let i = 0; i < 20; i++) {
    try {
      token += image.charAt(1000 + i * 100) || '-';
    } catch {
      token += '-';
    }
  }
  return token;
};
