<template>
  <form @submit.prevent="submit">
    <h2>{{ title }}</h2>
    <slot />
  </form>
</template>

<script>
export default {
  props: {
    title: null,
  },
  computed: {},
};
</script>

<style scoped>
h2 {
  /*text-align: center;*/
}
</style>
